export default [
    {
        path: "eventHall/lists",
        name: "jiDong-eventHall-lists",
        component: () => import('v/jiDong/eventHall/Lists'),
        meta: {
            title: "事件大厅",
            roleId: [3, 4],
            icon: 'ydfont ydkaoshi',
            is_jiDong: true,
            authorization: true
        }
    }
];
