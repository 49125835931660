export default [
    {
        path: "group/lists",
        name: "admin-group-lists",
        component: () => import('v/admin/group/Lists'),
        meta: {
            title: "监控组管理",
            is_admin: true,
            authorization:true
        }
            
    },
    {
        path: "group/AdjustExaminee",
        name: "AdjustExaminee",
        component: () => import('v/admin/group/AdjustExaminee'),
        meta: {
            title: "调整监控组-考生",
            is_admin: true,
            authorization:true
        },
    },
    {
        path: "group/AdjustInvigilator",
        name: "AdjustInvigilator",
        component: () => import('v/admin/group/AdjustInvigilator'),
        meta: {
            title: "调整监控组-监考",
            is_admin: true,
            authorization:true
        },
    }
    
];
