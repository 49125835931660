export default [
    {
        path: "examination/lists",
        name: "lists",
        component: () => import('v/index/examination/Lists'),
        meta: {
            title: "考试管理",
            authorization:true
        }
    },
    {
        path: "examination/ExamStudentInfoList",
        name: "ExamStudentInfoList",
        component: () => import('v/index/examination/ExamStudentInfoList'),
        meta: {
            title: "考试管理",
            authorization:true
        }
    },
    {
        path: "examination/VideoList",
        name: "VideoList",
        component: () => import('v/index/examination/VideoList'),
        meta: {
            title: "考试管理",
            authorization:true
        }
    },
];
