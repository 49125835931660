export default [
    {
        path: "generalGroup/lists",
        name: "jiDong-generalGroup-lists",
        component: () => import('v/jiDong/generalGroup/Lists'),
        meta: {
            title: "普通组数据监控",
            roleId: [5, 6, 7],
            icon: 'ydfont ydjiqunjiankong',
            is_jiDong: true,
            authorization:true
        }
    },
];
