export default [
    {
        path: "examvideopw/lists",
        name: "admin-examvideopw-lists",
        component: () => import("v/admin/examvideopw/Lists"),
        meta: {
            title: "评委在线监控管理",
            is_admin: true,
            authorization:true
        },
    }
];
