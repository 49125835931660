export default [
    {
        path: "examination/lists",
        name: "admin-examination-lists",
        component: () => import('v/admin/examination/Lists'),
        meta: {
            title: "考试管理",
            is_admin: true,
            authorization:true
        },
        // children: {
        //     path: "examination/ExamDialog",
        //     name: "ExamDialog",
        //     component: () => import('c/admin/examination/ExamDialog')
        // }
    },
    {
        path: "examination/ExaminationSubject",
        name: "ExaminationSubject",
        component: () => import('v/admin/examination/ExaminationSubject'),
        meta: {
            title: "考试管理-科目列表",
            is_admin: true,
            authorization:true
        },
    },
];
