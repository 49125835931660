import eventHall from "./eventHall";
import generalGroup from "./generalGroup";
import specialGroup from "./specialGroup";
import alreadySolved from "./alreadySolved";
import engineDriven from "./engineDriven"

export default [
    ...eventHall,
    ...generalGroup,
    ...specialGroup,
    ...alreadySolved,
    ...engineDriven,
]

