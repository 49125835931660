export default [
  {
    path: "/",
    name: "index-login",
    component: () => import(/* webpackChunkName: "login" */ "v/index/login/Login.vue"),
    meta: {
      title: "登录",
    },
  },
];
