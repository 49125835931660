export default [
  {
    path: "home",
    name: "admin-home",
    component: () => import( "v/admin/home/index"),
    meta: {
      title: "后台首页",
      is_admin: true,
      authorization:true
    },
  },
];
