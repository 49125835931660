export default [
    {
        path: "staff/lists",
        name: "admin-staff-lists",
        component: () => import('v/admin/staff/Lists'),
        meta: {
            title: "监考人员管理",
            is_admin: true,
            authorization: true
        }
            
    },
];
