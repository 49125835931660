export default [
  {
    path: "login",
    name: "admin-login",
    component: () => import(/* webpackChunkName: "login" */ "v/admin/login/Login.vue"),
    meta: {
      title: "登录",
      is_admin: true
    },
  },
  {
    path: "reset",
    name: "admin-reset",
    component: () => import(/* webpackChunkName: "login" */ "v/admin/login/Reset.vue"),
    meta: {
      title: "重置密码",
      is_admin: true
    },
  },
];
