export default [
    {
        path: "engineDriven/lists",
        name: "jiDong-engineDriven-lists",
        component: () => import('v/jiDong/engineDriven/Lists'),
        meta: {
            title: "机动管理",
            roleId: [6],
            icon: 'ydfont yddanganhe',
            is_jiDong: true,
            authorization:true
        }
    },
];
