export default [
    {
        path: "alreadySolved/lists",
        name: "jiDong-alreadySolved-lists",
        component: () => import('v/jiDong/alreadySolved/Lists'),
        meta: {
            title: "异常事件列表",
            roleId: [6, 7],
            icon: 'ydfont yddanganhe',
            is_jiDong: true,
            authorization: true
        }
    },
];
