export default [
    {
        path: "videorecord/lists",
        name: "admin-videorecord-lists",
        component: () => import("v/admin/videorecord/Lists"),
        meta: {
            title: "考试列表",
            is_admin: true,
            authorization: true
        },
    },
    {
        path: "videorecord/examlist",
        name: "admin-videorecord-examlist",
        component: () => import("v/admin/videorecord/examlist"),
        meta: {
            title: "科目列表",
            is_admin: true,
            authorization: true
        },
    },
    {
        path: "videorecord/monitorgroup",
        name: "admin-videorecord-monitorgroup",
        component: () => import("v/admin/videorecord/monitorgroup"),
        meta: {
            title: "监控组列表",
            is_admin: true,
            authorization: true
        },
    },
    {
        path: "videorecord/nameAndZkzNum",
        name: "name-and-zkzNum",
        component: () => import("v/admin/videorecord/nameAndzkzNum"),
        meta: {
            title: "学生姓名&准考证号",
            is_admin: true,
            authorization: true
        },
    },
    {
        path: "videorecord/imagesVideo",
        name: "images-video",
        component: () => import("v/admin/videorecord/imagesVideo"),
        meta: {
            title: "预警图片和视频",
            is_admin: true,
            authorization: true
        },
    },
    {
        path: "videorecord/imagesList",
        name: "images-list",
        component: () => import("v/admin/videorecord/imagesList"),
        meta: {
            title: "预警图片列表",
            is_admin: true,
            authorization: true
        },
    },
    {
        path: "videorecord/videoFront",
        name: "video-front",
        component: () => import("v/admin/videorecord/videoFront"),
        meta: {
            title: "正面监控列表",
            is_admin: true,
            authorization: true
        },
    },
    {
        path: "videorecord/videoBack",
        name: "video-back",
        component: () => import("v/admin/videorecord/videoBack"),
        meta: {
            title: "背面监控列表",
            is_admin: true,
            authorization: true
        },
    },
    {
        path: "videorecord/studentvideo",
        name: "admin-videorecord-studentvideo",
        component: () => import("v/admin/videorecord/studentvideo"),
        meta: {
            title: "考生视频列表",
            is_admin: true,
            authorization: true
        },
    },
    {
        path: "videorecord/playvideo",
        name: "admin-videorecord-playvideo",
        component: () => import("v/admin/videorecord/playvideo"),
        meta: {
            title: "播放视频",
            is_admin: true,
            authorization: true
        },
    },
];
