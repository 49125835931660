export default [
  {
    path: "login",
    name: "jiDong-login",
    component: () => import(/* webpackChunkName: "login" */ "v/jiDong/login/Login.vue"),
    meta: {
      title: "登录",
      is_jiDong: true
    },
  },
];
