export default [
    {
        path: "examvideo/lists",
        name: "admin-examvideo-lists",
        component: () => import("v/admin/examvideo/Lists"),
        meta: {
            title: "在线监控管理",
            is_admin: true,
            authorization:true
        },
    }
];
