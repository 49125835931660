export default [
    {
        path: "examinee/lists",
        name: "admin-examinee-lists",
        component: () => import("v/admin/examinee/Lists"),
        meta: {
            title: "考生管理",
            is_admin: true,
            authorization:true
        },
    },
];
