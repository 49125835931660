export default [
    {
        path: "specialGroup/lists",
        name: "jiDong-specialGroup-lists",
        component: () => import('v/jiDong/specialGroup/Lists'),
        meta: {
            title: "特别组数据监控",
            roleId: [5, 6, 7],
            icon: 'ydfont yddanganhe',
            is_jiDong: true,
            authorization: true
        }
    },
];
